import { bsc, bscTestnet, manta, sepolia } from 'viem/chains';
import commonConfigs from './common.json';
import devConfigs from './development.json';
import prodConfigs from './production.json';

const { ETH_MAINNET: mainnet } = commonConfigs;

const stakeContractMapTestnet = {
  [sepolia.id]: {
    managerContract: '0x05c9334cbaEf6b615563EB3a8ae35D1ad9F25985', // ManagerAdapter
    routerContract: '0xA2e7cE4E125EEA4241c969139398f2544ECa8acc'
  },
  [devConfigs.MANTA_PACIFIC_CHAIN.id]: {
    managerContract: '0x2340A4ad49E05ff0D881BFC081E980AdF69f1043', // DepositManger v2
    routerContract: '0x1142ae530987Ce9F498bA4D3fA72feD3C6148243',
    mantaManagerContract: '0x6648055Ab79c371a2714a98795B9aE530Bd6768c' // Manger Contract
  },
  [bscTestnet.id]: {
    managerContract: '0xd3752A738B0461ad59Ea47D6812F0Da678189eC0', // ManagerAdapter
    routerContract: '0xfa3E300034A919e68f7c376BCf56a516030a7d18'
  }
};
const stakeContractMapMainnet = {
  [mainnet.id]: {
    managerContract: '0x82A21E04f1851408e814c3d3Fa64b5d02Aa8D573',
    routerContract: '0x323446390012a5438Bb32F5329D3aB3eeF4ffC95'
  },
  [prodConfigs.MANTA_PACIFIC_CHAIN.id]: {
    managerContract: '0x3aCB6C0169a37885230852eD0ecF5126bBe8Be8a', // DepositManger v2
    mantaManagerContract: '0xC0D14A4Dc2F91Cf0EA761e16474d6E46fa986EE4', // Manger Contract
    routerContract: '0x323446390012a5438Bb32F5329D3aB3eeF4ffC95'
  },
  [bsc.id]: {
    managerContract: '0x649d4524897cE85A864DC2a2D5A11Adb3044f44a',
    routerContract: '0x2CF5900D1F47f49CE8D0d08718B88a84db075560'
  }
};
const supportedChainTestnet = [
  devConfigs.MANTA_PACIFIC_CHAIN,
  sepolia,
  bscTestnet
];
const supportedChainMainnet = [manta, mainnet, bsc];

const config =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_CONFIG_NODE_ENV === 'development'
    ? {
        ...devConfigs,
        supportedChain: supportedChainTestnet,
        stakeContractMap: stakeContractMapTestnet
      }
    : {
        ...prodConfigs,
        supportedChain: supportedChainMainnet,
        stakeContractMap: stakeContractMapMainnet
      };
export default {
  ...config,
  ...commonConfigs,
  mainnet
};
